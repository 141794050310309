"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Title = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  const data = _taggedTemplateLiteral(["\n  margin: 0;\n  margin-bottom: 8px;\n  color: white;\n  font-size: 20px;\n  line-height: 20px;\n  font-family: \"Muli\", sans-serif;\n  font-weight: 600px;\n  text-transform: uppercase;\n  letter-spacing: 5px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

const Title = _styledComponents.default.div(_templateObject());

exports.Title = Title;