"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "WanikaniDashboard", {
  enumerable: true,
  get: function get() {
    return _dashboard.WanikaniDashboard;
  }
});
Object.defineProperty(exports, "useLocalKey", {
  enumerable: true,
  get: function get() {
    return _useLocalKey.useLocalKey;
  }
});

var _dashboard = require("./dashboard");

var _useLocalKey = require("./useLocalKey");