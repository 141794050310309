import React from "react";
import styled from "styled-components";
import { colors } from "kyou-elements";
import { WanikaniDashboard } from "wk-components";
import { trackLink } from "../../analytics";

const Component = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  height: 30px;
  padding: 5px 15px;
  color: ${colors.WHITE};
  justify-content: space-between;
  font-size: 14px;
`;

const Separator = styled.span`
  display: inline-block;
  margin: 0px 10px;
`;

const Link = styled.span`
  display: inline-block;
  color: ${colors.WHITE};
  cursor: pointer;
`;

export const Dashboard = ({ apiKey, onLogout }) => {
  return (
    <Component>
      <WanikaniDashboard apiKey={apiKey} />
      <Footer>
        <div>
          <Link
            onClick={() => {
              trackLink("visit_benkyou", "http://raf.dev");
            }}
          >
            By Rafael Mendiola
          </Link>
          <Separator>|</Separator>
          <Link
            onClick={() => {
              trackLink("visit_benkyou", "https://benkyou.cards");
            }}
          >
            Benkyou flashcards
          </Link>
          <Separator>|</Separator>
          <Link
            onClick={() => {
              trackLink(
                "visit_coffee",
                "https://www.buymeacoffee.com/rafmendiola",
              );
            }}
          >
            Buy me a coffee{" "}
            <span role="img" aria-label="coffee">
              ☕️
            </span>
          </Link>
        </div>
        <Link onClick={onLogout}>Logout</Link>
      </Footer>
    </Component>
  );
};
