"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLocalKey = void 0;

var _react = require("react");

var _wanikaniClient = require("./wanikani-client");

const storage = window.localStorage;
const STORAGE_KEY = "apiKey";

const useLocalKey = () => {
  const [reactiveKey, setReactiveKey] = (0, _react.useState)(null);
  const [keyError, setKeyError] = (0, _react.useState)(null);
  (0, _react.useEffect)(() => {
    const apiKey = storage.getItem(STORAGE_KEY);
    setReactiveKey(apiKey);
    (0, _wanikaniClient.setAuthorization)(apiKey);
  }, []);

  const setApiKey = key => {
    storage.setItem(STORAGE_KEY, key);
    setReactiveKey(key);
    (0, _wanikaniClient.setAuthorization)(key);
  };

  const tryApiKey = async key => {
    setKeyError(null);

    try {
      const username = await (0, _wanikaniClient.checkAPI)(key);
      setApiKey(key);
      return username;
    } catch (e) {
      setKeyError("Key not accepted, try again");
      throw new Error("Key not accepted, try again");
    }
  };

  const clearApiKey = () => {
    storage.removeItem(STORAGE_KEY);
    setReactiveKey(null);
    (0, _wanikaniClient.setAuthorization)(null);
  };

  return {
    apiKey: reactiveKey,
    keyError,
    tryApiKey,
    clearApiKey
  };
};

exports.useLocalKey = useLocalKey;