"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWanikaniProgression = void 0;

var _react = require("react");

var _moment = _interopRequireDefault(require("moment"));

var _wanikaniClient = require("./wanikani-client");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const useWanikaniProgression = () => {
  const [levelProgression, setLevelProgression] = (0, _react.useState)({});
  const [isLoading, setIsLoading] = (0, _react.useState)(true);
  (0, _react.useEffect)(() => {
    const load = async () => {
      const progress = await (0, _wanikaniClient.getLevelProgression)();
      const todayMoment = (0, _moment.default)();
      const transformedProgress = progress.map((item, index, array) => {
        const {
          data: {
            level,
            unlockedAt,
            passedAt
          }
        } = item;
        const isLast = index === array.length - 1;
        const unlockedMoment = (0, _moment.default)(unlockedAt); // const startedMoment = moment(startedAt);

        const passedMoment = (0, _moment.default)(passedAt);
        let daysToComplete = passedMoment.diff(unlockedMoment, "days");

        if (isNaN(daysToComplete) && isLast) {
          daysToComplete = todayMoment.diff(unlockedMoment, "days");
        }

        return {
          level,
          unlockedAt,
          passedAt,
          daysToComplete
        };
      }); // console.log(transformedProgress);

      setLevelProgression(transformedProgress);
      setIsLoading(false);
    };

    load();
  }, []);
  return {
    isLoading,
    levelProgression
  };
};

exports.useWanikaniProgression = useWanikaniProgression;