"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLevelState = exports.getKanjiAssigmentsForLevel = exports.getKanjiSubjectsForLevel = exports.getSummary = exports.getAssignments = exports.getReviews = exports.getLevelProgression = exports.checkAPI = exports.getUser = exports.setAuthorization = void 0;

require("core-js/modules/es6.symbol");

require("core-js/modules/es6.array.sort");

require("core-js/modules/web.dom.iterable");

var _axiosCacheAdapter = require("axios-cache-adapter");

var _axios = _interopRequireDefault(require("axios"));

var _humps = _interopRequireDefault(require("humps"));

var _pick = _interopRequireDefault(require("lodash/pick"));

var _keyBy = _interopRequireDefault(require("lodash/keyBy"));

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const baseURL = "https://api.wanikani.com/v2";
const CACHE_MINS = 30;
const SLOW_CACHE_MINS = 60 * 24; // 1 day

const checkFromCache = response => {
  const {
    config: {
      baseURL: base,
      url
    },
    request: {
      fromCache
    }
  } = response;
  console.log("".concat(base).concat(url, " from cache: ").concat(fromCache));
};

const generateClient = maxAge => {
  // Create `axios` instance with pre-configured `axios-cache-adapter` attached to it
  return (0, _axiosCacheAdapter.setup)({
    // axios options
    baseURL,
    transformResponse: [..._axios.default.defaults.transformResponse, data => {
      return _humps.default.camelizeKeys(data);
    }],
    // `axios-cache-adapter` options
    cache: {
      maxAge
    }
  });
};

const wanikaniClient = generateClient(CACHE_MINS * 60 * 1000);
const noCacheClient = generateClient(0);
const longCacheClient = generateClient(SLOW_CACHE_MINS * 60 * 1000);

const setAuthorization = token => {
  wanikaniClient.defaults.headers.common.Authorization = "Bearer ".concat(token);
  longCacheClient.defaults.headers.common.Authorization = "Bearer ".concat(token);
};

exports.setAuthorization = setAuthorization;

const getUser = async () => {
  const response = await wanikaniClient.get("/user");
  const {
    data: {
      data
    }
  } = response;
  return data;
};

exports.getUser = getUser;

const checkAPI = async token => {
  try {
    const response = await noCacheClient.get("/user", {
      headers: {
        Authorization: "Bearer ".concat(token)
      }
    });
    const {
      data: {
        data
      }
    } = response;
    checkFromCache(response);

    if (!data.username) {
      throw new Error("key not accepted");
    }

    return data.username;
  } catch (e) {
    throw new Error("key not accepted");
  }
};

exports.checkAPI = checkAPI;

const getLevelProgression = async () => {
  const response = await wanikaniClient.get("/level_progressions");
  const {
    data: {
      data
    }
  } = response;
  return data;
};

exports.getLevelProgression = getLevelProgression;

const getReviews = () => {
  return wanikaniClient.get("/user");
};

exports.getReviews = getReviews;

const getAssignments = params => {
  return wanikaniClient.get("/assignments", {
    params
  });
};

exports.getAssignments = getAssignments;

const getSummary = async () => {
  const response = await wanikaniClient.get("/summary");
  const {
    data: {
      data
    }
  } = response;
  const reviews = data.reviews.reduce((acc, next) => {
    const {
      subjectIds,
      availableAt
    } = next;
    const reviewTime = (0, _moment.default)(availableAt);

    if (reviewTime.isBefore()) {
      return acc + subjectIds.length;
    }

    return acc;
  }, 0);
  const lessons = data.lessons.reduce((acc, review) => {
    return acc + review.subjectIds.length;
  }, 0);
  return {
    lessons,
    reviews
  };
};

exports.getSummary = getSummary;

const getKanjiSubjectsForLevel = async level => {
  const response = await longCacheClient.get("/subjects", {
    params: {
      levels: level,
      types: "kanji"
    }
  });
  checkFromCache(response);
  const {
    data: {
      data: levelData
    }
  } = response;
  const filtered = levelData.map(item => {
    const {
      data,
      id
    } = item;

    const returnVal = _objectSpread({
      subjectId: id
    }, (0, _pick.default)(data, ["subjectId", "characters", "componentSubjectIds", "documentUrl", "lessonPosition", "meaningHint", "meaningMnemonic", "meanings", "readingHint", "readingMnemonic", "readings"]));

    return returnVal;
  });
  return filtered;
};

exports.getKanjiSubjectsForLevel = getKanjiSubjectsForLevel;

const getKanjiAssigmentsForLevel = async level => {
  const response = await getAssignments({
    levels: level,
    subject_types: "kanji"
  });
  const {
    data: {
      data: levelData
    }
  } = response;
  const filtered = levelData.map((_ref) => {
    let {
      data
    } = _ref;
    return (0, _pick.default)(data, ["subjectId", "passed", "srsStage"]);
  });
  return filtered;
};

exports.getKanjiAssigmentsForLevel = getKanjiAssigmentsForLevel;

const getLevelState = async level => {
  const [assignments, subjects] = await Promise.all([getKanjiAssigmentsForLevel(level), getKanjiSubjectsForLevel(level)]); // console.log("level assigments:");
  // console.log(assignments);
  // console.log("kanji subjects:");
  // console.log(subjects);

  const assignmentsMap = (0, _keyBy.default)(assignments, "subjectId");
  const emptyAssigment = {
    passed: null,
    srsStage: null
  };
  const mergedSubjects = subjects.map(subject => {
    const {
      subjectId
    } = subject;
    const assignment = assignmentsMap[subjectId] || emptyAssigment;
    return _objectSpread({}, subject, {}, assignment);
  });
  const transformedSubjects = mergedSubjects.map(subject => {
    const {
      meanings,
      readings
    } = subject;
    const meaningsList = meanings.reduce((acc, item) => {
      const s = item.meaning;
      return [...acc, s];
    }, []);
    const readingsList = readings.reduce((acc, item) => {
      const s = item.reading;
      return [...acc, s];
    }, []);
    return _objectSpread({}, subject, {
      meaningsList,
      readingsList
    });
  });
  const levelKanji = transformedSubjects.sort((a, b) => a.lessonPosition > b.lessonPosition ? 1 : -1);
  const passedKanji = levelKanji.filter(subject => subject.passed);
  const remainingKanji = levelKanji.filter(subject => !subject.passed);
  return {
    levelKanji,
    passedKanji,
    remainingKanji
  };
};

exports.getLevelState = getLevelState;