"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.colors = void 0;
const colors = {
  DARK_PURPLE: "#37364A",
  DARK_BLUE: "#474688",
  LIGHT_BLUE: "#66AAFF",
  OFF_BLUE: "rgba(100, 100, 200, .1)",
  DARK_GREY: "#21222C",
  WHITE: "white",
  OFF_WHITE: "rgba(255, 255, 255, .8)",
  YELLOW: "#D7EBBA",
  GREEN: "#48CE8D",
  RED: "#FF6666",
  WANI_PINK: "#EE19A0",
  WANI_GRAY: "#C7C7C7",
  WANI_BLUE: "#1DABFB",
  CHART_GRAY: "#C7C7C7"
};
exports.colors = colors;