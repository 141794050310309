import React from "react";
import styled from "styled-components";
import { KeyGate } from "./components/KeyGate";
import { colors } from "kyou-elements";
import { initAnalytics } from "./analytics";

initAnalytics();

const Component = styled.div`
  display: flex;
  flex: 1;
  background-color: ${colors.DARK_GREY};
`;

function App() {
  return (
    <Component>
      <KeyGate />
    </Component>
  );
}

export default App;
