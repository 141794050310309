import amplitude from "amplitude-js";

const PREFIX = process.env.NODE_ENV === "production" ? "" : "DEV_";

export const initAnalytics = () => {
  amplitude.getInstance().init("6ce8ebeae9acd2b3718b73eeee6e373c");
};

export const logEvent = (type, params = null, callback) => {
  amplitude.getInstance().logEvent(`${PREFIX}${type}`, params, callback);
};

export const setUserId = (userId) => {
  amplitude.getInstance().setUserId(`${PREFIX}${userId}`);
};

export const trackLink = (eventType, url) => {
  logEvent(eventType, null, () => {
    window.location = url;
  });
};
