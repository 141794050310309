"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWanikaniStatus = void 0;

var _react = require("react");

var _wanikaniClient = require("./wanikani-client");

const useWanikaniStatus = () => {
  const [levelStatus, setLevelStatus] = (0, _react.useState)({});
  const [isLoading, setIsLoading] = (0, _react.useState)(true);
  (0, _react.useEffect)(() => {
    const load = async () => {
      const userData = await (0, _wanikaniClient.getUser)();
      const {
        level
      } = userData;
      const {
        levelKanji,
        passedKanji,
        remainingKanji
      } = await (0, _wanikaniClient.getLevelState)(level);
      const {
        lessons,
        reviews
      } = await (0, _wanikaniClient.getSummary)(); // console.log("--summary--");
      // console.log(lessons, reviews, levelKanji, passedKanji, remainingKanji);
      // console.log("--getting level data--");
      // console.log(levelStatus);

      setLevelStatus({
        level,
        lessons,
        reviews,
        levelKanji,
        passedKanji,
        remainingKanji
      });
      setIsLoading(false);
    };

    load();
  }, []);
  return {
    isLoading,
    levelStatus
  };
};

exports.useWanikaniStatus = useWanikaniStatus;