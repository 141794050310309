import React, { useState } from "react";
import { useLocalKey } from "wk-components";
import { colors } from "kyou-elements";
import debounce from "lodash/debounce";
import styled from "styled-components";
import { Panel, Title } from "kyou-elements";
import { Dashboard } from "../Dashboard";
import { logEvent, setUserId } from "../../analytics";

const dLogEvent = debounce(logEvent, 300);

const Gate = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const PanelContainer = styled.div`
  max-width: 500px;
  color: ${colors.WHITE};
`;

const SubTitle = styled.p`
  font-size: 14px;
  line-height: 1.6;
  color: ${colors.WHITE};
`;

const Form = styled.form``;
const FormLabel = styled.label`
  margin-top: 20px;
  font-size: 16px;
  color: ${colors.WANI_PINK};
`;

const InputContainer = styled.div`
  display: flex;
  margin: 10px 0px;
`;

const InputText = styled.input`
  flex: 1;
  margin: 0px;
  border: none;
  font-size: 16px;
  height: 30px;
  padding: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 10px;
`;

const InputButton = styled.input`
  background-color: ${colors.WANI_BLUE};
  color: white;
  font-size: 16px;
  padding: 0px 15px;
  margin: 0px;
  border: none;
  height: 30px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const Error = styled.div`
  color: ${colors.YELLOW};
`;

export const KeyGate = () => {
  const { apiKey, tryApiKey, keyError, clearApiKey } = useLocalKey();
  const [text, setText] = useState("");

  const handleLogout = () => {
    clearApiKey();
    logEvent("logout");
  };

  const handleSubmit = (event) => {
    const load = async () => {
      try {
        // Add a check that the API key works here
        const username = await tryApiKey(text); // if this works, apiKey gets set

        setUserId(username);
        setText("");
        logEvent("checkAPIKeySuccess");
      } catch (e) {
        logEvent("checkAPIKeyError");
      }
    };
    load();
    event.preventDefault();
  };

  if (apiKey) {
    dLogEvent("rendered-dashboard");
    return <Dashboard apiKey={apiKey} onLogout={handleLogout} />;
  }

  dLogEvent("rendered-gate");
  return (
    <Gate>
      <PanelContainer>
        <Panel>
          <Title>WaniKani Dashboard</Title>
          <SubTitle>
            This WaniKani dashboard lets you see your current level, the kanji
            you still have to learn, and your level progression. It's best
            viewed on a big screen monitor.
          </SubTitle>
          <Form onSubmit={handleSubmit}>
            <FormLabel>Enter your WaniKani v2 API key:</FormLabel>
            <InputContainer>
              <InputText
                type="text"
                name="apiKey"
                value={text}
                onChange={(event) => {
                  setText(event.target.value);
                }}
              />
              <InputButton type="submit" value="Submit" />
            </InputContainer>
            {keyError !== null && <Error>{keyError}</Error>}
          </Form>
        </Panel>
      </PanelContainer>
    </Gate>
  );
};
